<!-- eslint-disable vue/this-in-template -->
<template>
  <b-modal
    id="modalConfiguracoesEcac"
    ref="modalConfiguracoesEcac"
    size="xl"
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    header-class="pb-0"
  >
    <componente-ecac
      v-model="this.value.configuracao"
    />
    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <b-btn
          variant="none"
          size="sm"
          class="btn-outline-success btn-none"
          style="margin-left: 0.3rem;"
          @click="salvarConfiguracoes()"
        >
          Salvar
        </b-btn>
        <b-btn
          variant="none"
          size="sm"
          class="btn-outline-danger btn-none"
          style="margin-left: 0.3rem;"
          @click="close(true)"
        >
          Cancelar
        </b-btn>
      </div>
    </template>
  </b-modal>
</template>

<style lang="scss">

#modalConfiguracoesEcac {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: center !important;

  .modal-xl {
    display: flex;
    width: calc(60vw);
  }
}

</style>

<script>

import api from '@/app/empresas/shared/services/api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ModalConfiguracoesEcac',
  components: {
    ComponenteEcac: () => import('./components/ComponenteEcac.vue'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      configInIn: {
        responsavelLegal: false,
        processos: [],
      },
      config: {
        responsavelLegal: false,
        processos: [],
      },
    }
  },
  watch: {
    value(val, oldVal) {
      if (val !== oldVal) {
        this.config = val.configuracao
      }
    },
  },
  mounted() {
    this.value.configuracao.razaoSocial = this.value.razaoSocial
    this.configIn = this.value.configuracao
    this.config = this.value.configuracao
    this.show()
  },
  methods: {
    show() {
      this.$refs.modalConfiguracoesEcac.show()
    },
    close(reset = false) {
      if (reset) {
        this.value.configuracao = this.configIn
      }
      this.$emit('hidden')
    },
    salvarConfiguracoes() {
      api.atualizarConfiguracoesEcac(this.value.id, this.config)
        .then(payload => {
          if (!payload.data) {
            throw new Error()
          } else {
            this.$sucesso('Cadastro salvo com sucesso!')
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível salvar as configurações das rotinas.',
              icon: 'fa-solid fa-circle-exclamation',
              variant: 'danger',
            },
          })
          throw err
        })
        .finally(() => {
          this.$refs.modalConfiguracoesEcac.hide()
          this.$emit('hidden')
        })
    },
  },
}
</script>
